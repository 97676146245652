.move-to-top {
    position: relative;
    top: -130px;
    /* top: -78px; */
    margin-bottom: -100px;
    /* Compensate for the space left by moving the div */
}

/* Add responsive styles */
@media (max-width: 768px) {
    .move-to-top {
        top: -50px;
        /* Adjust as needed */
        margin-bottom: -50px;
        /* Adjust as needed */
    }
}

@media (max-width: 576px) {
    .move-to-top {
        top: -30px;
        /* Adjust as needed */
        margin-bottom: -30px;
        /* Adjust as needed */
    }
}

@media (max-width: 576px) {
    .alignicons {
        margin-left: -3%;
    }
}

@media (max-width: 576px) {
    .previewBtn {
        margin-Top: 1%;
    }
}

.AutoComplete {
    margin-left: 5%;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .AutoComplete {
        margin-left: 5%;
    }
}

@media (max-width: 576px) {
    .AutoComplete {
        margin-left: 0%;
    }
}

.HeadingBlogList {
    margin-left: 10%;
}

/* Add responsive styles */
@media (max-width: 768px) {
    .HeadingBlogList {
        margin-left: 10%;
    }
}

@media (max-width: 576px) {
    .HeadingBlogList {
        margin-left: 0%;
    }
}

.ql-toolbar.ql-snow {
    background-color: #ccc;
}

/* landing screen*/

.rounded-1 {
    background-color: #1A1D29;
    /* background-color: #922022; */
}

.rounded-2 {
    /* background-color: #1A1D29; */
    background-color: #922022;
}

.accordion-header {
    font-size: 16px !important;
    font-weight: bold;
}

.faqheading {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    border-radius: 6px;
    background-color: #1A1D29;
    color: #F6F7F9;
    border: 1px solid #1A1D29;
    padding: 1%;
}

@media (max-width: 767px) {
    .wide-on-mobile {
        width: 100%;
        max-width: none;
    }
}

/*  */

/* New Campaign screen */

.accordion_style1 {
    font-size: 20px !important;
    color: #344054;
    font-weight: bold;
    background-color: #D0D5DD;
    border-radius: 5px;
}

/*  */

/* still working loader css */
.loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
    0% {
        box-shadow: 20px 0 #4BA5EB, -20px 0 #4BA5EB33;
        background: #4BA5EB;
    }

    33% {
        box-shadow: 20px 0 #4BA5EB, -20px 0 #4BA5EB33;
        background: #4BA5EB33;
    }

    66% {
        box-shadow: 20px 0 #4BA5EB33, -20px 0 #4BA5EB;
        background: #4BA5EB33;
    }

    100% {
        box-shadow: 20px 0 #4BA5EB33, -20px 0 #4BA5EB;
        background: #4BA5EB;
    }
}

/*  */

/* unsplash full preview image on mouse hover */
.thumbnail-container {
    position: relative;
    display: inline-block;
}

.thumbnail {
    z-index: 1;
    transition: opacity 0.3s ease;
}

.full-image {
    position: absolute;
    top: 0;
    left: -100%;
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 300px;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.thumbnail-container:hover .full-image {
    opacity: 1;
}

.thumbnail-container:hover .thumbnail {
    opacity: 0;
}

/*  */

/* new landing transition effect */

.section {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
    transform: translateY(20px);
}

.section.visible {
    opacity: 1;
    transform: translateY(0);
}

/*  */

/* new landing responsive */
/* @media (max-width: 1024px) {
    .responsive-p-custopti {
        width: 100% !important;
    }
} */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .responsive-col-podcastsec {
        width: 84% !important;
        margin-left: 5% !important;
    }

    .responsive-p-custopti {
        width: 100% !important;
    }
}

/*  */